export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LIGHT_THEME = "LIGHT_THEME";
export const DARK_THEME = "DARK_THEME";
export const PARAMS = "PARAMS";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_TOKEN = "SET_TOKEN";
export const SET_NAVBAR = "SET_NAVBAR";
export const SET_USER = "SET_USER";
export const SET_LOGOUT_MODAL = "SET_LOGOUT_MODAL";
export const SET_BUFFER = "SET_BUFFER";
export const CONFIRM_MODAL = "CONFIRM_MODAL";
export const SET_APPOINTMENT = "SET_APPOINTMENT";
export const SET_OFFICES = "SET_OFFICES";
export const APROVALS = "APROVALS";
export const SET_BOOKING = "SET_BOOKING";
export const SET_CATEGORY = "SET_CATEGORY";