import {
  LOGIN,
  LOGOUT,
  LIGHT_THEME,
  DARK_THEME,
  SET_NOTIFICATION,
  SET_TOKEN,
  SET_NAVBAR,
  SET_USER,
  SET_LOGOUT_MODAL,
  SET_BUFFER,
  CONFIRM_MODAL,
  SET_APPOINTMENT,
  SET_OFFICES,
  APROVALS,
  SET_BOOKING,
  SET_CATEGORY
} from "./actionTypes";

export const Reducers = (state, action) => {
  switch (action.type) {
    case SET_APPOINTMENT:
      const appointment = {
        ...state.appointment,
        ...action.payload
      };
      localStorage.setItem("appointment", JSON.stringify(appointment));
      return {
        ...state,
        appointment: action.payload
      };
    case LOGIN:
      return {
        ...state,
        isLogin: true,
        token: action.payload
      };
    case LOGOUT:
      return {
        ...state,
        isLogin: false,
        token: null,
        role: null,
        user: [],
        title: null
      };
    case LIGHT_THEME:
      localStorage.setItem("theme", "light");
      return {
        ...state,
        theme: "light"
      };
    case DARK_THEME:
      localStorage.setItem("theme", "darkmode");
      return {
        ...state,
        theme: "darkmode"
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case SET_NAVBAR:
      return {
        ...state,
        navbarShow: action.payload
      };
    case SET_USER:
      localStorage.setItem("users", JSON.stringify(action.payload));
      let rootPath =
        action.payload.role === "admin"
          ? "/admin"
          : action.payload.role === "sales"
          ? "/sales"
          : "/office";

      return {
        ...state,
        user: action.payload,
        role: action.payload.role,
        title: action.payload.title,
        rootPath: rootPath
      };
    case SET_LOGOUT_MODAL:
      return {
        ...state,
        isLogoutModal: action.payload
      };
    case SET_BUFFER:
      return {
        ...state,
        buffer: action.payload
      };
    case CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: action.payload
      };
    case SET_OFFICES:
      return {
        ...state,
        offices: action.payload
      };
    case APROVALS:
      return {
        ...state,
        approvals_count: action.payload
      };
    case SET_BOOKING:
      const booking = {
        ...state.booking,
        ...action.payload
      };
      localStorage.setItem("booking", JSON.stringify(booking));
      return {
        ...state,
        booking: action.payload
      };
    case SET_CATEGORY:
      const categories = action.payload;
      localStorage.setItem("categories", JSON.stringify(categories));
      return {
        ...state,
        categories: action.payload
      };
    default:
      return state;
  }
};
