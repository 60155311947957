import React, {
  useContext,
  createContext,
  useReducer,
  useMemo,
  useEffect
} from "react";
import { Reducers } from "./reducers";
import States from "./states";
import {
  LOGIN,
  LOGOUT,
  SET_USER,
  SET_APPOINTMENT,
  DARK_THEME,
  LIGHT_THEME,
  SET_CATEGORY
} from "./actionTypes";
export const contextApi = createContext(null);
export const useContextApi = () => useContext(contextApi);

function ContextProvider({ children }) {
  const [state, dispatch] = useReducer(Reducers, States);

  useEffect(() => {
    async function checkAllSequentially() {
      try {
        await CheckedLogin(dispatch);

        await Promise.all([
          CheckedAppintment(dispatch),
          CheckedTheme(dispatch),
          CheckedCategory(dispatch)
        ]);
      } catch (error) {
        console.error("Bir hata oluştu:", error);
      }
    }
    checkAllSequentially();
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      dispatch
    }),
    [state]
  );

  return <contextApi.Provider value={value}>{children}</contextApi.Provider>;
}

function CheckedLogin(dispatch) {
  const token = localStorage.getItem("token");
  const users = JSON.parse(localStorage.getItem("users"));
  if (users && token) {
    dispatch({ type: LOGIN, payload: token });
    dispatch({
      type: SET_USER,
      payload: users
    });
  } else {
    dispatch({ type: LOGOUT });
  }
}

function CheckedAppintment(dispatch) {
  const appointment = JSON.parse(localStorage.getItem("appointment"));
  if (appointment) {
    dispatch({
      type: SET_APPOINTMENT,
      payload: appointment
    });
  }
}

function CheckedTheme(dispatch) {
  const theme = localStorage.getItem("theme");
  if (theme === "darkmode") {
    dispatch({ type: DARK_THEME });
  } else {
    dispatch({ type: LIGHT_THEME });
  }
}

const CheckedCategory = (dispatch) => {
  const categories = JSON.parse(localStorage.getItem("categories"));
  if (categories) {
    dispatch({
      type: SET_CATEGORY,
      payload: categories
    });
  }
};

export default ContextProvider;
