const States = {
  theme: "",
  isLogoutModal: false,
  isLogin: false,
  token: null,
  user: [],
  role: null,
  notification: {
    title: "Title",
    body: "Content",
    isShow: false
  },
  rootPath: "",
  alert: {
    title: "Title",
    content: "Content",
    isShow: false
  },
  default_avatar: "https://ui-avatars.com/api/?name=",
  navbarShow: false,
  buffer: [],
  app_colors: {
    approved: "var(--success-light)",
    canceled: "var(--error-dark)",
    confirmed: "var(--confirmed)",
    pending: "var(--warning-dark)",
    completed: "var(--success-dark)",
    null: "var(--disabled)"
  },
  confirmModal: {
    show: false,
    title: "Title",
    message: "Message",
    onConfirm: () => {},
    onCancel: () => {}
  },
  roles: {
    admin: "Admin",
    sales: "Satış Temsilcisi",
    service: "Hizmet Yetkilisi",
    user: "Uzman"
  },
  plans: [
    { id: "nonactive", name: "Demo" },
    { id: "full", name: "Premium" },
    { id: "pending", name: "Onay Bekliyor" },
    { id: "free", name: "Ücretsiz" }
  ],
  offices: [],
  categories: [],
  appointment: {
    type: null,
    group: null,
    member: null,
    service: null,
    date: null,
    hours: []
  },
  booking: {
    step: 1,
    type: "service",
    group: null,
    member: null,
    location: null,
    category: null,
    service: null,
    date: null,
    hours: []
  },
  approvals_count: 0,
};

export default States;
