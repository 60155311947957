import React, { Suspense, useState, useContext, useEffect } from "react";
import { contextApi } from "./contexts/context";
import Spinner from "react-bootstrap/Spinner";
import Notification from "./components/notification";
import { ToastContainer } from "react-toastify";
// import AlertUI from "./components/alerts/alert";
import ConfirmationModal from "./components/UI/ConfirmationModal";
import "react-toastify/dist/ReactToastify.css";
import { Col } from "react-bootstrap";
import NetworkStatus from "./components/NetworkCheck";
const AdminLayout = React.lazy(() => import("./role_admin/layout"));
const ServiceLayout = React.lazy(() => import("./role_service/layout"));
const GuestLayout = React.lazy(() => import("./role_guest/layout"));
const UserLayout = React.lazy(() => import("./role_user/layout"));
const SalesLayout = React.lazy(() => import("./role_sales/layout"));

const App = () => {
  const {
    role,
    theme,
    user: { status, is_active },
    confirmModal: { show: showModal, onConfirm, onCancel, title, message }
  } = useContext(contextApi);
  const [Layout, setLayout] = useState(<GuestLayout />);
  useEffect(() => {
    const layouts = {
      admin: <AdminLayout />,
      sales: <SalesLayout />,
      service: <ServiceLayout />,
      user: <UserLayout />
    };

    const currentLayout =
      status === "nonactive" || !is_active ? (
        <GuestLayout />
      ) : (
        layouts[role] || <GuestLayout />
      );
    setLayout(currentLayout);
  }, [role, status, is_active]);

  return (
    <Suspense
      fallback={
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Yükleniyor...</span>
          </Spinner>
        </div>
      }
    >
      {/* <AlertUI /> */}
      <Col className={theme}>
        <NetworkStatus />
        {Layout}
      </Col>
      <ConfirmationModal
        show={showModal}
        title={title}
        message={message}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <Notification />
      <ToastContainer hideProgressBar />
    </Suspense>
  );
};

export default App;
