import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CgDanger } from "react-icons/cg";

const ConfirmationModal = ({ show, title, message, onConfirm, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Header closeButton>
        {/* buraya bir icon ekleyelim */}

        <Modal.Title>
          {" "}
          <CgDanger className="text-warning" /> {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={onCancel}
          disabled={isLoading}
        >
          İptal
        </Button>
        <Button
          variant="secondary"
          onClick={handleConfirm}
          disabled={isLoading}
        >
          {isLoading ? "İşleniyor..." : "Devam"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
