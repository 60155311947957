import React from "react";
import { contextApi } from "../../contexts/context";
import { useContext } from "react";
import Toast from "react-bootstrap/Toast";
import { ToastContainer } from "react-bootstrap";
import { SET_NOTIFICATION } from "../../contexts/actionTypes";

const Index = () => {
  const { notification, dispatch } = useContext(contextApi);

  const NotifyClose = () => {
    dispatch({
      type: SET_NOTIFICATION,
      payload: {
        isShow: false,
        title: "",
        body: "",
        type: null
      }
    });
  };

  return (
    <ToastContainer className="p-3" position="top-center" onClick={NotifyClose}>
      <Toast show={notification.isShow} onClose={NotifyClose} autohide>
        <Toast.Header
          className={
            notification.type === "success"
              ? "bg-success text-white"
              : "bg-danger text-white"
          }
        >
          <strong className="me-auto">{notification.title}</strong>
        </Toast.Header>
        <Toast.Body>{notification.body}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
export default Index;
